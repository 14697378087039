import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Unauthenticated from './pages/Unauthenticated';
import AppWithSplash from './AppWithSplash';
import { tenantId, clientId } from "./utils/config";

const msalConfig = {
	auth: {
		clientId: clientId,
		authority: "https://login.microsoftonline.com/" + tenantId,
		redirectUri: "/",
		postLogoutRedirectUri: "/"
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false
	}
};

const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	const allAccounts = msalInstance.getAllAccounts();
	let accountInTenant = null;
	allAccounts.forEach(a=>{
		if(a.tenantId === tenantId){
			accountInTenant = a;
		}
	})
	msalInstance.setActiveAccount(accountInTenant);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

export default function AppWithMSAL() {
	return (
		<MsalProvider instance={msalInstance}>
			<AuthenticatedTemplate>
				<AppWithSplash />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Unauthenticated />
			</UnauthenticatedTemplate>
		</MsalProvider>
	);
}