import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './styles/App.scss';

import Navigation from './components/Navigation';

const Home = lazy(() => import('./pages/Home'));
const Tenants = lazy(() => import('./pages/Tenants'));
const Tenant = lazy(() => import('./pages/Tenant'));
const Coc00ns = lazy(() => import('./pages/Coc00ns'));
const Coc00n = lazy(() => import('./pages/Coc00n'));
const Users = lazy(() => import('./pages/Users'));
const User = lazy(() => import('./pages/User'));

export default function App() {

	return (
		<BrowserRouter>
			<Navigation />
			<Suspense fallback={<></>}>
				<Routes>
					<Route path="/*" element={<Home />} />
					<Route path="/tenants" element={<Tenants />} />
					<Route path="/tenant/:id/:onMicrosoftSubDomain" element={<Tenant />} />
					<Route path="/coc00ns" element={<Coc00ns />} />
					<Route path="/coc00n/:tenantId/:coc00nId/:displayName" element={<Coc00n />} />
					<Route path="/users" element={<Users />} />
					<Route path="/user/:tenantId/:coc00nId/:userId/:name/:email" element={<User />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
