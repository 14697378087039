import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import CustomButton from './CustomButton';

export default function SignInSignOutButton() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            {isAuthenticated ?
                <CustomButton onClick={() => instance.logoutRedirect()}>Sign Out</CustomButton>
                : (instance !== InteractionStatus.Startup && instance !== InteractionStatus.HandleRedirect) ? // instance check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
                    <CustomButton onClick={() => instance.loginRedirect({ scopes: ["User.Read"], domainHint: "coc00n.onmicrosoft.com" })}>Sign In</CustomButton>
                    :
                    <></>
            }
        </>
    )

}