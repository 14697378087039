import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useMsal } from "@azure/msal-react";
import SignInSignOutButton from '../components/SignInSignOutButton';
import { version } from '../utils/config';
import { ReactComponent as Logo } from '../images/coc00n_logo_icon.svg';
import { ReactComponent as MenuIcon } from '../images/menu_icon.svg';
import { ReactComponent as CloseMenuIcon } from '../images/close_menu_icon.svg';
import { NavLink } from "react-router-dom";
import { useState } from 'react';

export default function Navigation() {
	const msal = useMsal();
	const [show, setShow] = useState(false);

	return (
		<Navbar expand={false} className="my-3 bg-off-white" sticky="top" collapseOnSelect>
			<Container>
				<Navbar.Brand as={NavLink} to="/" className="flex-grow-1" title="Home">
					<Logo style={{ height: (window.innerWidth < 576 ? "35" : "45") + "px" }} />
				</Navbar.Brand>
				<MenuIcon className="pointer" onClick={() => setShow(true)} style={{ height: (window.innerWidth < 576 ? "30" : "35") + "px", width: (window.innerWidth < 576 ? "30" : "35") + "px" }} />
				<Offcanvas placement="end" show={show} onHide={() => setShow(false)} className="text-off-white">
					<Offcanvas.Header className="d-flex justify-content-end">
						<CloseMenuIcon className="pointer" onClick={() => setShow(false)} style={{ height: (window.innerWidth < 576 ? "20" : "25") + "px", width: (window.innerWidth < 576 ? "20" : "25") + "px" }} />
					</Offcanvas.Header>
					<Offcanvas.Body className="d-flex flex-column p-5 align-items-start">
						<Nav className="fs-4 fw-bold d-flex flex-column flex-grow-1">
							<Nav.Link as={NavLink} eventKey="1" active={false} to="/" onClick={() => setShow(false)}>Home</Nav.Link>
							<Nav.Link as={NavLink} eventKey="2" active={false} to="/tenants" onClick={() => setShow(false)}>Tenants</Nav.Link>
							<Nav.Link as={NavLink} eventKey="3" active={false} to="/coc00ns" onClick={() => setShow(false)}>coc00ns</Nav.Link>
							<Nav.Link as={NavLink} eventKey="4" active={false} to="/users" onClick={() => setShow(false)}>Users</Nav.Link>
						</Nav>
						<div>Name: {msal.accounts[0]?.name}</div>
						<div className="mb-3">Email: {msal.accounts[0]?.username}</div>
						<SignInSignOutButton />
						<div className="mt-3 w-100 text-end fs-8">v{version}</div>
					</Offcanvas.Body>
				</Offcanvas>
			</Container>
		</Navbar>
	);
}
